import React from "react";

import { ImLocation } from "react-icons/im";
import { BsFillCameraFill, BsFillCameraReelsFill } from "react-icons/bs";
import { IoMdStar } from "react-icons/io";

export default function ListingDetails({ profile }) {
  const review = (
    profile.reviewsPublishedRating / profile.reviewsPublishedNo
  ).toFixed(1);

  // useEffect(() => {
  //   console.log(profile.reviewsPublishedRating);
  //   console.log(profile.reviewsPublishiedNo);
  // }, []);

  return (
    <div
      className="container justify-between leading-normal pl-2 w-1/2"
      key={profile.user}
    >
      <h5 className="font-bold tracking-tight text-black">{profile.name}</h5>
      <div className="flex content-center">
        <ImLocation />
        <span className="ml-1 align-middle">{profile.region}</span>
      </div>
      {review > 0 && (
        <div>
          <IoMdStar
            title="reviews"
            className="inline-block text-3xl text-orange-400"
          />
          <span className="inline-block align-bottom">{review}</span>
        </div>
      )}
      <div className="flex gap-2">
        {profile.images > 0 && (
          <BsFillCameraFill
            title="photographer"
            className="cursor-pointer text-black mx-1"
          />
        )}
        {profile.videos > 0 && (
          <BsFillCameraReelsFill
            title="videographer"
            className="cursor-pointer text-white mx-1"
          />
        )}
      </div>
      <div key={profile.user} className="">
        <div className="flex flex-wrap">
          {profile.images > 0 &&
            profile.photo_expertise &&
            profile.photo_expertise.map((item) => {
              const spaceReplace = item.replace("_", " ");
              return (
                <span
                  className="lowercase relative rounded-full px-2 m-1 text-center text-xs text-white bg-black"
                  key={item}
                >
                  {spaceReplace}
                </span>
              );
            })}
        </div>
        <div className="flex flex-wrap">
          {profile.videos > 0 &&
            profile.video_expertise &&
            profile.video_expertise.map((item) => {
              return (
                <span
                  className="relative rounded-full px-2 m-1 text-center text-xs bg-white"
                  key={item}
                >
                  {item}
                </span>
              );
            })}
        </div>
      </div>
      {profile.packagesPublished > 0 && (
        <div>
          <p className="text-xs decoration-2 underline decoration-orange-400">
            {profile.packagesPublished} Package(s)
          </p>
        </div>
      )}
      {profile.images > 0 && profile.photographer && (
        <div>
          <span className="italic text-xs ">
            Photographer R{profile.photographer_rate}/hour
          </span>
        </div>
      )}
      {profile.videos > 0 && profile.videographer && (
        <div>
          <span className="italic text-xs">
            Videographer R{profile.videographer_rate}/hour
          </span>
        </div>
      )}
    </div>
  );
}
